import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, description ,children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
	<div className="p-3 col-md-12">
	<h1>
        <Link to="/">{title}</Link>
		
      </h1>
	  <h2>{description}</h2>
	  <p><a href="https://www.hockeycomputindo.com/2022/04/modern-blog-with-gatsby-cuteblog-free.html">Read Documentation →</a>
<br/>
<a href="https://axcora.my.id/pico/cuteblog/project">Download Cuteblog for other technology →</a>
</p>
	  </div>
	
    )
  }

  return (
    <div className="container global-wrapper" data-is-root-path={isRootPath}>
  		<nav className="navbar p-3">
<p><Link className="nav-link" to="/">Home</Link></p>
<a className="nav-link btn btn-dark text-white" href="https://github.com/mesinkasir/cuteblog-gastby">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
  <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"></path>
</svg> Github</a>
</nav>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer className="text-center mt-3 p-1">
        <p>© {new Date().getFullYear()} {title}</p>
		Template by <a href="https://axcora.my.id/pico/cuteblog/">cuteblog</a> Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a> present by <a href="https://website.axcora.com">axcora technology</a>
      </footer>
    </div>
  )
}

export default Layout
